
.agreement{
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
::v-deep{
    .v-input--checkbox .v-label{
        font-size: 1.4rem;
        color: #111;
    }
    .v-input--checkbox .theme--light.v-icon {
        color: #ddd;
    }
}
